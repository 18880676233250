import React, { useEffect, useState } from "react";
import "./style.scss";
import Portfolio from "./portfolio";
import Testimonials from "./testimonials";
import ContactForm from "./contactForm";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Helmet } from "react-helmet";
import Watermillstone from "./detailPages/Watermillstone";
import ElizabethYount from "./detailPages/ElizabethYount";
import classNames from "classnames";
import Icons from "./images/icons.svg";
import LinkedInIcon from "./images/linkedIn.svg";
import FacebookIcon from "./images/facebook.svg";
import TwitterIcon from "./images/twitter.svg";

const YearsActive = (props) => {
	const { startingYear } = props;
	const currentYear = new Date().getFullYear();
	const yearsActive = currentYear - startingYear;
	return <strong>{yearsActive}</strong>;
};

const IndexPage = () => {
	const [selectedProject, setSelectedProject] = useState(0);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedImagePath, setSelectedImagePath] = useState(0);
	const [imageIsOpen, setImageIsOpen] = useState(false);
	const [hasReachedEnd, setHasReachedEnd] = useState(false);
	const [imageAltText, setImageAltText] = useState("");

	useEffect(() => {
		window.onscroll = () => {
			const offset = window.pageYOffset;
			const height = Math.max(
				document.body.getBoundingClientRect().height,
				document.documentElement.getBoundingClientRect().height
			);
			const viewportHeight = window.innerHeight;
			setHasReachedEnd(hasReachedEnd || offset + viewportHeight >= height);
		};
	}, [hasReachedEnd]);

	const getSelectedProject = (selectedProject) => {
		switch (selectedProject) {
			case "WatermillStone":
				return <Watermillstone />;
			case "ElizabethYount":
				return <ElizabethYount />;
			default:
				return <Watermillstone />;
		}
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale = 1.0, maximum-scale=1.0, user-scalable=no"
				/>
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<title>Mike Dailey Design</title>
				<link
					href="https://fonts.googleapis.com/css?family=Rubik:400,500,700,900"
					rel="stylesheet"
				/>
				<link
					href="https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i"
					rel="stylesheet"
				/>
				<link
					href="https://fonts.googleapis.com/css?family=Cambo&text=%26"
					rel="stylesheet"
				/>
				<link rel="stylesheet" href="css/style.css" />
				<script src="https://www.google.com/recaptcha/api.js?render=6Lfs3AwaAAAAAEfV2nymJNyqMkg1zMIuA-5ENRKj"></script>
			</Helmet>

			<div
				className={classNames([
					"modal",
					{ active: imageIsOpen },
					{ imagePath: selectedImagePath },
				])}
			>
				<div className="modal-content-container">
					<div className="modal-content">
						<button
							onClick={() => setImageIsOpen(false)}
							onKeyDown={() => setImageIsOpen(false)}
						>
							x
						</button>
						<img src={selectedImagePath} alt={imageAltText} />
					</div>
				</div>
			</div>

			<div
				className={classNames([
					"modal",
					{ active: modalIsOpen },
					{ content: selectedProject },
				])}
			>
				<div className="modal-content-container">
					<div className="modal-content">
						<button
							onClick={() => setModalIsOpen(false)}
							onKeyDown={() => setModalIsOpen(false)}
						>
							x
						</button>

						{getSelectedProject(selectedProject)}
					</div>
				</div>
			</div>
			<div
				className={classNames("modal-background", {
					"modal-active": modalIsOpen,
				})}
			></div>
			<div className="main-content">
				<header>
					<h1>Mike Dailey</h1>
					<p>
						I'm a hybrid{" "}
						<strong>
							graphic designer{" "}
							<span className="ampersand">
								<span>&amp;</span>
							</span>{" "}
							web developer
						</strong>{" "}
						with over&nbsp;
						<YearsActive startingYear={2003} />
						&nbsp;years of experience. I'm a pretty easy guy to work with, and I
						take a lot of pride in everything I do.
					</p>
					<div className="button_group">
						<button onClick={() => scrollTo("#portfolio")}>
							View Portfolio
						</button>
						<button onClick={() => scrollTo("#testimonials")}>
							Testimonials
						</button>
						<button onClick={() => scrollTo("#contact")}>Contact Me</button>
					</div>
					<div className="social_media_group">
						<button
							onClick={() =>
								window.open(
									"https://www.linkedin.com/in/michaelcdailey/",
									"_blank"
								)
							}
							className="icon-button"
						>
							<LinkedInIcon />
						</button>
						<button
							onClick={() =>
								window.open(
									"https://www.facebook.com/boxheadcreative/",
									"_blank"
								)
							}
							className="icon-button"
						>
							<FacebookIcon />
						</button>
						<button
							onClick={() =>
								window.open(
									"https://www.twitter.com/boxheadcreative/",
									"_blank"
								)
							}
							className="icon-button"
						>
							<TwitterIcon />
						</button>
					</div>
				</header>

				<Portfolio
					setSelectedProject={setSelectedProject}
					setModalIsOpen={setModalIsOpen}
					setSelectedImagePath={setSelectedImagePath}
					setImageIsOpen={setImageIsOpen}
					setImageAltText={setImageAltText}
				/>

				<Testimonials />

				<ContactForm />

				<footer className={classNames([{ reachedBottom: hasReachedEnd }])}>
					<Icons />
					<small id="copyright"></small>
				</footer>
			</div>
			<script
				src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
				async
				defer
			></script>
		</>
	);
};

export default IndexPage;
